import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import ContentBlock from '../blocks/ContentBlock';
import { PortraitImage } from '../blocks/PortraitImage';
import { Banner } from '../blocks/Banner';
import { CallToAction } from '../blocks/CallToAction';
import { DefaultLayout } from '../layouts/Default';

const BeratungsangebotPage = ({ data, location }) => {
  const { site, contactData } = data;
  const { opengraph, metadata, content, services, banner } = data.content;
  const { siteMetadata } = site;
  return (
    <DefaultLayout>
      <ContentBlock data={content} />
      <Banner data={banner} />
      {services.map((service, index) => (
        <ContentBlock data={service} key={service.title} />
      ))}
      <PortraitImage />
      <CallToAction data={contactData} />
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default BeratungsangebotPage;

export const BeratungsangebotPageQuery = graphql`
  query BeratungsangebotPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    contactData: cockpitRegionKontaktInformationen {
      id
      telephone
      email
    }
    content: cockpitRegionServiceangebot {
      id
      content {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      banner {
        id
        name
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        line1
        line2
        line3
        link {
          url
          label
          type
        }
      }
      services {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
